import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import {
  STATUS_IS_BLOCKED,
  STATUSES,
} from "../../common/constants/blockedStatus";
import { getEstablishmentStatus } from "../../common/constants/establishmentsStatuses";

export const CollectionModalItem = ({
  title,
  text,
  isBlocked = false,
  assessmentStatus,
}) => (
  <div className="collection-modal-item-row position-relative">
    <div className="collection-modal-item-right w-full">
      <Text textType="p3" className="collection-modal-item-title">
        {title}
      </Text>
      {Array.isArray(text) ? (
        <div className="flex items-center justify-between gap-x-2 w-full pe-4">
          {text.map((item, i) => {
            return (
              <div key={i} className={"flex flex-col gap-y-1 w-25"}>
                <span className="collection-modal-item-text-label font-effra">
                  {item.title}
                </span>
                <span className="collection-modal-item-text font-effra">
                  {item.text}
                </span>
                {/* {i + 1 !== text.length && <div className={"font-effra"}>،&nbsp;</div>} */}
              </div>
            );
          })}
        </div>
      ) : (
        <Text textType="p5" className="collection-modal-item-text">
          {text}
        </Text>
      )}
      {/* {isBlocked ? (
        <div className="flex items-center gap-x-2 mt-4 font-effra">
          <img src={"img/alert_icon.svg"} alt="alert" className="alert-image" />
          تم ايقاف المنشأة لعدم اكمال التقييم الذاتي
        </div>
      ) : ( */}
      <div className="flex items-center gap-x-2 mt-4 font-effra">
        <img
          src={getEstablishmentStatus(assessmentStatus).icon}
          alt={assessmentStatus}
          className="alert-image"
        />
        {getEstablishmentStatus(assessmentStatus).label}
      </div>
      {/* )} */}
    </div>
    {/* {isBlocked && (
      <div className="collection-modal-item-left w-fit max-w-fit position-absolute"
      style={{
        left: 0,
      }}
      >
        <Text
          Tag="p"
          textType="p6"
          className={`collection-modal-item-text collection-modal-item__item-status collection-modal-item__item-status--red`}
          isBold
        >
          {STATUSES[STATUS_IS_BLOCKED]}
        </Text>
      </div>
    )} */}
  </div>
);

CollectionModalItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isBlocked: PropTypes.bool,
  assessmentStatus: PropTypes.string,
};

export default CollectionModalItem;
