import React, { useEffect, useState } from "react";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../../lib/assets/icons/icons";
import filePreview from "../../../../../../../public/img/fileIcon.svg";
import word from "../../../../../../../public/img/word.svg";
import pdf from "../../../../../../../public/img/pdf.svg";
import jpg from "../../../../../../../public/img/jpg.svg";
import excel from "../../../../../../../public/img/excel.svg";
import "./attachmentUploader.css";

export const AttachmentUploader = ({
  name,
  index,
  getFile,
  file,
  removeFile,
  setErrors = () => {},
  errors,
  acceptedTypes,
  isShowDelete = true,
  customErrorMessage = null,
  isError = false,
  isMandatory = true,
}) => {
  const acceptedFileTypes = acceptedTypes
    ? acceptedTypes
    : "image/jpeg, image/jpg, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword";
  const acceptedTypesArray = acceptedFileTypes.replaceAll(" ", "").split(",");
  const acceptedFileTypesText = acceptedFileTypes
    .split(",")
    .map((type) => type.split("/").pop().toUpperCase())
    .join(", ");
  const [errorText, setErrorText] = useState();
  const [isRejectedFileType, setIsRejectedFileType] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: acceptedFileTypes,
    multiple: false,
    noClick: false,
    maxSize: 5 * 1024 * 1024,

    onDropRejected: (fileRejections) => {
      const rejectedFileType = fileRejections[0].file.type;

      if (rejectedFileType !== acceptedFileTypes) {
        setErrorText(
          `صيغة الملف الذي تحاول إضافته غير مدعومة، يرجى إرفاق ملف بالصيغ التالية ${acceptedFileTypesText}`
        );
      } else if (fileRejections[0].errors[0].code === "file-too-large") {
        setErrorText("حجم الملف يجب أن يكون 5 ميجا بايت أو أقل");
      } else if (fileRejections[0].errors[0].code === "file-invalid-type")
        setErrorText(`نوع الملف غير مسموح به `);

      setIsRejectedFileType(false);
    },

    onDragEnter: (event) => {
      const fileType = event.dataTransfer.items[0].type;
      setErrors({ ...errors, attachments: null });
      setErrorText(undefined);
      if (acceptedTypesArray.includes(fileType)) {
        setIsRejectedFileType(false);
      } else {
        setIsRejectedFileType(true);
      }
    },
    onDragLeave: () => {
      setIsRejectedFileType(false);
    },
  });

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      if (file) {
        getFile({ file, index });
      }
    });
  }, [acceptedFiles]);

  const getAttachmentImage = (attachment) => {
    const fileType = attachment?.file_name
      ? attachment.file_name.split(".").pop().toLowerCase()
      : attachment.name.split(".").pop().toLowerCase();

    switch (fileType) {
      case "doc":
      case "docx":
        return word;

      case "pdf":
        return pdf;

      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
        return jpg;

      case "xlsx":
        return excel;

      default:
        return filePreview;
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setErrorText(undefined);
    setErrors({ ...errors, attachments: null });

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setErrorText("حجم الملف يجب أن يكون 5 ميجا بايت أو أقل");
      event.target.value = ""; // clear the file input
      return;
    }

    // Validate file type
    const isAcceptedType = acceptedTypesArray.includes(file.type);
    if (!isAcceptedType) {
      setErrorText(`يجب أن يكون المرفق بصيغة ${acceptedFileTypesText}`);
      event.target.value = "";
      return;
    }

    getFile({ file, index });
  };
  return (
    <div className="flex-1 flex flex-col gap-y-2 attachment-uploader-wrapper">
      <Text
        Tag={"div"}
        textType="roman"
        fontSize={"font-16"}
        style={{ color: "#505050" }}
        className="required-attachment-name"
      >
        {name}
        <span className="attachment-optional">
          {" "}
          {isMandatory || !isShowDelete ? "" : "(اختياري)"}
        </span>
      </Text>
      {file ? (
        <div
          className={
            "w-full h-[80px] py-2 bg-white border border-dashed border-gray-300 flex items-center justify-between rounded-md attachment-box"
          }
        >
          <div className="flex items-center justify-center attachment-type-image">
            <img
              src={getAttachmentImage(file)}
              className="view-attachments-image"
            />
          </div>
          <div className="attachment-name-wrapper">
            <a
              href={file?.url_attachment ? file?.url_attachment : null}
              target="_blank"
            >
              <Text
                Tag="div"
                textType="bold"
                fontSize={"font-15"}
                className={
                  "my-auto font-mol text-sm text-ims-turquoise_blue attachment-file-name"
                }
              >
                {file?.name ?? file?.file_name}
              </Text>
            </a>
          </div>
          {isShowDelete && (
            <button
              className={
                "w-5 h-5 my-auto  flex items-center justify-center bg-gray-50"
              }
              onClick={() => removeFile(index ? index : file.id)}
            >
              <img src={"img/xIcon.svg"} className="w-4 h-4 " />
            </button>
          )}
        </div>
      ) : (
        <div>
          <div
            {...getRootProps()}
            className={
              "w-full h-[160px] bg-white border-2 border-dashed border-gray-300 flex flex-col items-center justify-center gap-y-1 rounded-md"
            }
            style={{
              height: 160,
              border: isError ? "1px dashed red" : "2px dashed #d1d5db",
            }}
          >
            <input {...getInputProps()} onChange={handleFileChange} />

            <div className={"flex items-baseline justify-center gap-x-px"}>
              <p
                style={{ marginBottom: 0 }}
                className={"font-effra text-sm leading-4 text-gray-400"}
              >
                {"قم بسحب الملف أو "}{" "}
              </p>
              <p
                style={{ marginBottom: 0 }}
                className={
                  "font-effra text-sm leading-4 font-semibold text-ims-turquoise_blue"
                }
              >
                {"تحديده من جهازك"}{" "}
              </p>
              <UploadIcon className={"mx-1 w-4 h-4 text-gray-400"} />
            </div>
            <div className={"h-4"} />
            <>
              <p
                style={{ marginBottom: 0 }}
                className={"font-effra text-sm text-gray-400"}
              >
                {acceptedFileTypesText}
              </p>
              <p
                style={{ marginBottom: 0 }}
                className={"font-effra text-sm text-gray-400"}
              >
                {"الحد الأقصى لحجم الملف هو 5 ميقا"}
              </p>
            </>
          </div>

          {errorText && !isError && !customErrorMessage && (
            <Text
              Tag={"div"}
              textType="roman"
              fontSize={"font-16"}
              className="mt-4 leading-8  "
              style={{ color: "red" }}
            >
              * {errorText}
            </Text>
          )}
          {isError ? (
            <div>
              <Text
                Tag={"div"}
                textType="roman"
                fontSize={"font-16"}
                className="mt-4 leading-8 "
                style={{ color: "red" }}
              >
                * يرجى إرفاق الملفات المطلوبة
              </Text>
            </div>
          ) : null}
          {customErrorMessage ? (
            <div>
              <Text
                Tag={"div"}
                textType="roman"
                fontSize={"font-16"}
                className="mt-4 leading-8 "
                style={{ color: "red" }}
              >
                * {customErrorMessage}
              </Text>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
